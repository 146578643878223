import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import axios from "axios";
import { backendUrl } from "utils/env";
import { IconChevronCompactDown, IconChevronCompactUp } from "@tabler/icons-react";
import { token } from "utils/userToken";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CrmValueStatements = () => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [expandedLevels, setExpandedLevels] = useState({}); // Track expanded state for each level
  const route = useLocation().pathname.split("/").slice(1);
  const accountNo = route[route.length - 1];
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    const flattenedData = data.flatMap((subItem, subIndex) =>
      subItem.subSubParams.flatMap((subSubItem, subSubIndex) =>
        subSubItem.valueStatementDescriptions.map((description, descriptionIndex) => {
          console.log(subIndex, subSubIndex, descriptionIndex);
          const decision = formData?.[subIndex]?.[subSubIndex]?.[descriptionIndex]?.decision || "";

          const remarks = formData?.[subIndex]?.[subSubIndex]?.[descriptionIndex]?.remarks || "";

          return {
            subParam: subItem.subParam,
            subSubParam: subSubItem.subSubParam,
            description: description.description,
            valueStatementSubCode: description.valueStatementSubCode,
            decision,
            remarks,
          };
        })
      )
    );
    const url = backendUrl + `/accountWiseCRM/save?accountNo=${accountNo}`;
    try {
      const res = await axios.post(url, flattenedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(res.data.message);
      navigate("/crm");
    } catch (error) {
      toast.error(error?.message || error?.response?.data?.message);
    }
  };

  const getORM = async () => {
    try {
      const res = await axios.get(`${backendUrl}/rbia/CRM`);
      setData(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleLevel = (levelKey) => {
    setExpandedLevels((prev) => ({
      ...prev,
      [levelKey]: !prev[levelKey],
    }));
  };
  useEffect(() => {
    getORM();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {data.map((subItem, subIndex) => {
              const subKey = `sub_${subIndex}`;
              return (
                <div
                  key={subIndex}
                  style={{
                    marginBottom: "2rem",
                    border: "1px solid lightgray",
                    padding: "1rem",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleLevel(subKey)}
                  >
                    <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                      {subIndex + 1}. {subItem.subParam}
                    </Typography>
                    {expandedLevels[subKey] ? <IconChevronCompactUp /> : <IconChevronCompactDown />}
                  </div>
                  {expandedLevels[subKey] &&
                    subItem.subSubParams.map((subSubItem, subSubIndex) => {
                      const subSubKey = `subSub_${subIndex}_${subSubIndex}`;
                      return (
                        <div
                          key={subSubIndex}
                          style={{
                            marginBottom: "1rem",
                            paddingLeft: "1rem",
                            borderLeft: "2px solid lightgray",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleLevel(subSubKey)}
                          >
                            <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                              {subSubIndex + 1}. {subSubItem.subSubParam}
                            </Typography>
                            {expandedLevels[subSubKey] ? (
                              <IconChevronCompactUp />
                            ) : (
                              <IconChevronCompactDown />
                            )}
                          </div>
                          {expandedLevels[subSubKey] &&
                            subSubItem?.valueStatementDescriptions?.map(
                              (description, descriptionIndex) => (
                                <div
                                  key={descriptionIndex}
                                  style={{ marginBottom: "1rem", marginLeft: "15px" }}
                                >
                                  <Typography>
                                    {descriptionIndex + 1}. {description.description}
                                  </Typography>

                                  <Controller
                                    name={`${subIndex}.${subSubIndex}.${descriptionIndex}.decision`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <FormControl>
                                        <RadioGroup {...field} row>
                                          <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                          />
                                          <FormControlLabel
                                            value=""
                                            control={<Radio />}
                                            label="Not Applicable"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  />

                                  {/* Conditionally render remarks field based on the decision */}
                                  {watch(
                                    `${subIndex}.${subSubIndex}.${descriptionIndex}.decision`
                                  ) === "yes" && (
                                    <Controller
                                      name={`${subIndex}.${subSubIndex}.${descriptionIndex}.remarks`}
                                      control={control}
                                      defaultValue=""
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          label="Additional Comments"
                                          variant="outlined"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          style={{ marginTop: "8px" }}
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
            <Stack justifyContent={"center"}>
              <Button
                variant="contained"
                className="ActionButtonsColor"
                type="submit"
                disabled={isSubmitting || loading}
                style={{ marginTop: "1rem" }}
              >
                {isSubmitting ? <CircularProgress size={22} /> : "Submit"}
              </Button>
            </Stack>
          </form>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default CrmValueStatements;
