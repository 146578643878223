import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Grid, Box, Typography, Stack, CircularProgress } from "@mui/material";
import Loader from "components/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import axios from "axios";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";

const defaultValues = {
  lastTotalAdvances: "",
  currentTotalAdvances: "",
  changeTotalAdvances: "0",
  lastTotalDeposits: "",
  currentTotalDeposits: "",
  changeTotalDeposits: "0",
  lastTotalNpa: "",
  currentTotalNpa: "",
  changeTotalNpa: "0",
  lastTotalWrittenOffAdvances: "",
  currentTotalWrittenOffAdvances: "",
  changeTotalWrittenOffAdvances: "0",
  comments: "",
};

const BusinessRisk = () => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues,
  });

  const watchLastTotalAdvances = watch("lastTotalAdvances");
  const watchCurrentTotalAdvances = watch("currentTotalAdvances");
  const watchLastTotalDeposits = watch("lastTotalDeposits");
  const watchCurrentTotalDeposits = watch("currentTotalDeposits");
  const watchLastTotalNpa = watch("lastTotalNpa");
  const watchCurrentTotalNpa = watch("currentTotalNpa");
  const watchLastTotalWrittenOffAdvances = watch("lastTotalWrittenOffAdvances");
  const watchCurrentTotalWrittenOffAdvances = watch("currentTotalWrittenOffAdvances");

  useEffect(() => {
    // Calculate for Advances
    if (watchLastTotalAdvances !== undefined && watchCurrentTotalAdvances !== undefined) {
      calculateChangePercentage(
        watchCurrentTotalAdvances,
        watchLastTotalAdvances,
        watchLastTotalAdvances,
        "changeTotalAdvances"
      );
    }
    // Calculate for Deposits
    if (watchLastTotalDeposits !== undefined && watchCurrentTotalDeposits !== undefined) {
      calculateChangePercentage(
        watchCurrentTotalDeposits,
        watchLastTotalDeposits,
        watchLastTotalDeposits,
        "changeTotalDeposits"
      );
    }
    // Calculate for NPA
    if (watchLastTotalNpa !== undefined && watchCurrentTotalNpa !== undefined) {
      calculateChangePercentage(
        watchLastTotalNpa,
        watchCurrentTotalNpa,
        watchLastTotalNpa,
        "changeTotalNpa"
      );
    }
    // Calculate for Written Off Advances
    if (
      watchLastTotalWrittenOffAdvances !== undefined &&
      watchCurrentTotalWrittenOffAdvances !== undefined
    ) {
      calculateChangePercentage(
        watchLastTotalWrittenOffAdvances,
        watchCurrentTotalWrittenOffAdvances,
        watchLastTotalWrittenOffAdvances,
        "changeTotalWrittenOffAdvances"
      );
    }
  }, [
    watchLastTotalAdvances,
    watchCurrentTotalAdvances,
    watchLastTotalDeposits,
    watchCurrentTotalDeposits,
    watchLastTotalNpa,
    watchCurrentTotalNpa,
    watchLastTotalWrittenOffAdvances,
    watchCurrentTotalWrittenOffAdvances,
  ]);

  const calculateChangePercentage = (current, last, divider, field) => {
    const lastValue = parseFloat(last) || 0;
    const currentValue = parseFloat(current) || 0;

    let percentage =
      lastValue > 0 ? (((currentValue - lastValue) / divider) * 100).toFixed(2) : "0";

    // If percentage is negative, set it to 0
    if (parseFloat(percentage) < 0) {
      percentage = "0";
    }

    setValue(field, percentage);
  };

  const onSubmit = async (data) => {
    const url = backendUrl + "/businessRisk/save";
    try {
      const res = await axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
      toast.success(res.data.message);
      reset(defaultValues);
    } catch (error) {
      toast.error(error?.reponse?.data?.message || error?.message);
    }
    console.log("Form Submitted", data);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box sx={{ padding: 3, mt: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name={`lastRbiaDate`}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    required
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    label="Last RBIA Date"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            {["Advances", "Deposits", "Npa", "WrittenOffAdvances"].map((field) => (
              <Grid item xs={12} sm={6} md={4} key={field}>
                <Typography variant="h5" mb={1}>
                  {field === "Npa" ? "NPA" : field.replace(/([A-Z])/g, " $1")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name={`lastTotal${field}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          required
                          {...field}
                          label="Last Total"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`currentTotal${field}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="number"
                          {...field}
                          label="Current Total"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`changeTotal${field}`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type="text"
                          {...field}
                          label="Change %"
                          variant="outlined"
                          fullWidth
                          InputProps={{ readOnly: true }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Controller
                name={`comments`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: true }}
                    label="Comments"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack justifyContent={"center"} mt={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="ActionButtonsColor"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={22} /> : "Submit"}
            </Button>
          </Stack>
        </form>
      </Box>
    </DashboardLayout>
  );
};

export default BusinessRisk;
