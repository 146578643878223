import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Stack,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Loader from "components/Loader";
import axios from "axios";
import { styled } from "@mui/system";
import { backendUrl } from "utils/env";
import { IconChevronCompactDown, IconChevronCompactUp, IconDownload } from "@tabler/icons-react";
import { token } from "utils/userToken";
import { toast } from "react-toastify";
import moment from "moment";

const Orm = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [expandedLevels, setExpandedLevels] = useState({}); // Track expanded state for each level

  const onSubmit = async (formData) => {
    const flattenedData = data.flatMap((subItem, subIndex) =>
      subItem.subSubParams.flatMap((subSubItem, subSubIndex) =>
        subSubItem.valueStatementDescriptions.map((description, descriptionIndex) => {
          console.log(subIndex, subSubIndex, descriptionIndex);
          const decision = formData?.[subIndex]?.[subSubIndex]?.[descriptionIndex]?.decision || "";

          const remarks = formData?.[subIndex]?.[subSubIndex]?.[descriptionIndex]?.remarks || "";

          return {
            subParam: subItem.subParam,
            subSubParam: subSubItem.subSubParam,
            description: description.description,
            valueStatementSubCode: description.valueStatementSubCode,
            decision,
            remarks,
          };
        })
      )
    );
    const url = backendUrl + "/branchWiseORM/save";
    try {
      const res = await axios.post(url, flattenedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(res.data.message);
      getHistory();
    } catch (error) {
      toast.error(error?.message || error?.response?.data?.message);
    }
  };

  const getORM = async () => {
    try {
      const res = await axios.get(`${backendUrl}/rbia/ORM`);
      setData(res.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleLevel = (levelKey) => {
    setExpandedLevels((prev) => ({
      ...prev,
      [levelKey]: !prev[levelKey],
    }));
  };

  //   history
  const [history, setHistory] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const getHistory = async () => {
    const url = backendUrl + "/branchWiseORM/history";
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setHistory(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const [downloadingID, setDownloadingID] = useState();
  const handleReportDownload = async (id) => {
    setDownloadingID(id);
    try {
      const url = backendUrl + `/branchWiseORM/downloadPdf/${id}`;
      window.open(url, "_blank");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setDownloadingID(null);
    }
  };

  const renderSortArrow = (column) => {
    if (sortColumn !== column) return null;
    return sortDirection === "asc" ? " \u2191" : " \u2193";
  };
  const StyledTableRow = styled(TableRow)(({ theme, comment }) => ({
    backgroundColor: comment ? "#ffcccc !important" : "",
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  useEffect(() => {
    getORM();
    getHistory();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
              mb: 2,
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Branch Name {renderSortArrow("branchName")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Branch Code {renderSortArrow("branchCode")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Submitted By {renderSortArrow("createdBy")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Date {renderSortArrow("createdDateTime")}
                  </TableCell>

                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {history.length ? (
                  history.map((row, index) => (
                    <StyledTableRow key={index + 1} comment={row.rejectComments}>
                      <TableCell className="NewTableCells" align="left">
                        {row.branchName}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.branchCode}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.createdBy}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.createdDateTime
                          ? moment(row.createdDateTime).format("DD-MM-YYYY hh:mm")
                          : "---"}
                      </TableCell>

                      <TableCell className="NewTableCells" align="left">
                        <Button
                          disabled={downloadingID}
                          onClick={() => handleReportDownload(row.uniqueId)}
                        >
                          {downloadingID == row.uniqueId ? (
                            <CircularProgress size={22} />
                          ) : (
                            <>
                              <IconDownload style={{ marginRight: "10px" }} /> Report
                            </>
                          )}
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            {data.map((subItem, subIndex) => {
              const subKey = `sub_${subIndex}`;
              return (
                <div
                  key={subIndex}
                  style={{
                    marginBottom: "2rem",
                    border: "1px solid lightgray",
                    padding: "1rem",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleLevel(subKey)}
                  >
                    <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                      {subIndex + 1}. {subItem.subParam}
                    </Typography>
                    {expandedLevels[subKey] ? <IconChevronCompactUp /> : <IconChevronCompactDown />}
                  </div>
                  {expandedLevels[subKey] &&
                    subItem.subSubParams.map((subSubItem, subSubIndex) => {
                      const subSubKey = `subSub_${subIndex}_${subSubIndex}`;
                      return (
                        <div
                          key={subSubIndex}
                          style={{
                            marginBottom: "1rem",
                            paddingLeft: "1rem",
                            borderLeft: "2px solid lightgray",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleLevel(subSubKey)}
                          >
                            <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                              {subSubIndex + 1}. {subSubItem.subSubParam}
                            </Typography>
                            {expandedLevels[subSubKey] ? (
                              <IconChevronCompactUp />
                            ) : (
                              <IconChevronCompactDown />
                            )}
                          </div>

                          {expandedLevels[subSubKey] &&
                            subSubItem?.valueStatementDescriptions?.map(
                              (description, descriptionIndex) => (
                                <div
                                  key={descriptionIndex}
                                  style={{ marginBottom: "1rem", marginLeft: "15px" }}
                                >
                                  <Typography>
                                    {descriptionIndex + 1}. {description.description}
                                  </Typography>

                                  <Controller
                                    name={`${subIndex}.${subSubIndex}.${descriptionIndex}.decision`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <FormControl>
                                        <RadioGroup {...field} row>
                                          <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                          />
                                          <FormControlLabel
                                            value=""
                                            control={<Radio />}
                                            label="Not Applicable"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  />

                                  {/* Conditionally render remarks field based on the decision */}
                                  {watch(
                                    `${subIndex}.${subSubIndex}.${descriptionIndex}.decision`
                                  ) === "yes" && (
                                    <Controller
                                      name={`${subIndex}.${subSubIndex}.${descriptionIndex}.remarks`}
                                      control={control}
                                      defaultValue=""
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          label="Additional Comments"
                                          variant="outlined"
                                          fullWidth
                                          multiline
                                          rows={2}
                                          style={{ marginTop: "8px" }}
                                        />
                                      )}
                                    />
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
            {data?.length ? (
              <Stack justifyContent={"center"}>
                <Button
                  variant="contained"
                  className="ActionButtonsColor"
                  type="submit"
                  disabled={isSubmitting || loading}
                  style={{ marginTop: "1rem" }}
                >
                  {isSubmitting ? <CircularProgress size={22} /> : "Submit"}
                </Button>
              </Stack>
            ) : null}
          </form>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default Orm;
