import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { TextField } from "@mui/material";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const [credential, setCredential] = useState({
    mail: "",
    password: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setCredential((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(credential);
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    if (credential?.mail && credential?.password) {
      if (
        (credential.mail === "authority@gmail.com" || credential.mail === "branch@gmail.com") &&
        credential.password === "1234"
      ) {
        sessionStorage.setItem("mail", credential.mail);
        sessionStorage.setItem("password", credential.password);
        sessionStorage.setItem(
          "level",
          credential.mail.includes("authority") ? "authority" : "branch"
        );
        navigate("/dashboard");
      } else {
        alert("Invalid mail or password");
      }
    } else {
      alert("Please provide credential");
    }
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSignIn}>
            <MDBox mb={2}>
              <TextField
                type="email"
                // disabled
                required
                label="Email"
                value={credential?.mail}
                name="mail"
                fullWidth
                onChange={handleInput}
              />
            </MDBox>
            <MDBox mb={2}>
              <TextField
                type="password"
                // disabled
                name="password"
                required
                value={credential?.password}
                label="Password"
                fullWidth
                onChange={handleInput}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
