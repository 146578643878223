import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import ReactECharts from "echarts-for-react";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader";
import generatePDF from "react-to-pdf";
import { toast } from "react-toastify";

const BusinessRiskDashboard = () => {
  const [businessRiskData, setBusinessRiskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const targetRef = useRef();

  // Fetch available dates when the component mounts
  useEffect(() => {
    const getDropDown = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${backendUrl}/businessRisk/get`,
          headers: { Authorization: `Bearer ${token}` },
        });
        setData(response.data.data);
        setSelectedValue(response.data.data[0]?.id || ""); // Select the first available date by default
      } catch (error) {
        console.error("Error fetching dates:", error);
      }
    };
    getDropDown();
  }, []);

  // Fetch business risk data when a date is selected
  useEffect(() => {
    if (selectedValue) {
      const fetchBusinessRiskData = async () => {
        setLoading(true);
        try {
          const response = await axios({
            method: "get",
            url: `${backendUrl}/businessRisk/getScore/${selectedValue}`,
            headers: { Authorization: `Bearer ${token}` },
          });
          setBusinessRiskData(response.data.data);
        } catch (error) {
          console.error("Error fetching business risk data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchBusinessRiskData();
    }
  }, [selectedValue]);

  // Handle date change
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Calculate difference and style risk
  const calculateDifference = (last, current) => {
    if (last === 0) return current === 0 ? "0" : "100"; // Prevent division by 0
    const difference = ((current - last) / last) * 100;
    return difference.toFixed(2);
  };

  // Chart options generator for different metrics
  const generateChartOptions = (title, data) => {
    return {
      title: {
        text: title,
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "pie",
          radius: "60%",
          data: data.map((item) => ({
            name: item.name,
            value: item.value,
          })),
        },
      ],
    };
  };

  // Gauge chart options for Total Risk Score
  const generateRiskScoreChartOptions = (totalRiskScore, interpretation) => {
    return {
      series: [
        {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          radius: "90%",
          splitNumber: 10,
          itemStyle: {
            color: "#58D9F9",
            shadowColor: "rgba(0,138,255,0.45)",
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
          progress: {
            show: true,
            roundCap: true,
            width: 18,
          },
          pointer: {
            icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
            length: "75%",
            width: 16,
            offsetCenter: [0, "5%"],
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 18,
            },
          },
          axisTick: {
            splitNumber: 2,
            lineStyle: {
              width: 2,
              color: "#999",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              width: 3,
              color: "#999",
            },
          },
          axisLabel: {
            distance: 30,
            color: "#999",
            fontSize: 20,
          },
          title: {
            show: false,
          },
          detail: {
            backgroundColor: "#fff",
            valueAnimation: true,
            formatter: function (value) {
              return `{value|${value.toFixed(0)}}\n{unit|${interpretation}}`;
            },
            rich: {
              value: {
                fontSize: 50,
                fontWeight: "bolder",
                color: "#777",
              },
              unit: {
                fontSize: 20,
                color: "#FF4500", // Change to indicate severity
              },
            },
          },
          data: [
            {
              value: totalRiskScore || 0, // Default to 0 if no data
            },
          ],
        },
      ],
    };
  };

  // Default values when data is missing
  const defaultRiskData = {
    scores: {
      advancesRiskScore: 0,
      depositsRiskScore: 0,
      npaRiskScore: 0,
      writtenOffRiskScore: 0,
      totalRiskScore: 0,
      interpretation: "No Data",
    },
    businessRisk: {
      lastTotalAdvances: 0,
      currentTotalAdvances: 0,
      lastTotalDeposits: 0,
      currentTotalDeposits: 0,
      lastTotalNpa: 0,
      currentTotalNpa: 0,
      lastTotalWrittenOffAdvances: 0,
      currentTotalWrittenOffAdvances: 0,
      changeTotalWrittenOffAdvances: 0,
      changeTotalNpa: 0,
      changeTotalDeposits: 0,
      changeTotalAdvances: 0,
      comments: "No Comments",
    },
  };

  const downloadReport = () => {
    const url = backendUrl + `/businessRisk/download/${selectedValue}`;
    window.open(url, "_blank");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? <Loader /> : null}
      <Box sx={{ padding: 3 }}>
        {/* <button onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}>
          Download PDF
        </button> */}
        <Button
          disabled={!selectedValue}
          onClick={downloadReport}
          className="ActionButtonsColor"
          variant="contained"
          sx={{ ml: "auto", display: "block", mb: 1 }}
        >
          Download Report
        </Button>
        {/* Dropdown to select a date */}
        <FormControl fullWidth>
          <InputLabel id="date-select-label">Select Current RBIA Date</InputLabel>
          <Select
            labelId="date-select-label"
            value={selectedValue}
            onChange={handleChange}
            label="Select Current RBIA Date"
          >
            {data?.map((values) => (
              <MenuItem key={values.id} value={values.id}>
                {values.currentRbiaDate}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Display Cards for Current, Last, and Difference */}
        <Grid container spacing={3} mt={1} ref={targetRef}>
          {/* Display Pie Chart for Risk Breakdown */}
          <Grid item xs={12} md={6}>
            <Card sx={{ border: "1px solid lightgray" }}>
              <CardContent>
                <Typography variant="h6" mb={2}>
                  Risk Score Breakdown
                </Typography>
                <ReactECharts
                  option={generateChartOptions("", [
                    {
                      name: "Advances Risk Score",
                      value: (businessRiskData || defaultRiskData).scores.advancesRiskScore,
                    },
                    {
                      name: "Deposits Risk Score",
                      value: (businessRiskData || defaultRiskData).scores.depositsRiskScore,
                    },
                    {
                      name: "NPA Risk Score",
                      value: (businessRiskData || defaultRiskData).scores.npaRiskScore,
                    },
                    {
                      name: "Written-Off Risk Score",
                      value: (businessRiskData || defaultRiskData).scores.writtenOffRiskScore,
                    },
                  ])}
                  style={{ width: "100%", height: "350px" }}
                />
              </CardContent>
            </Card>
          </Grid>

          {/* Total Risk Score Gauge Chart */}
          <Grid item xs={12} md={6}>
            <Card sx={{ border: "1px solid lightgray" }}>
              <CardContent>
                <Typography variant="h6" mb={2}>
                  Total Marks
                </Typography>
                <ReactECharts
                  option={generateRiskScoreChartOptions(
                    (businessRiskData || defaultRiskData).scores.totalRiskScore,
                    (businessRiskData || defaultRiskData).scores.interpretation
                  )}
                  style={{ height: "350px", width: "100%" }}
                />
              </CardContent>
            </Card>
          </Grid>

          {/* Display Metrics Cards */}
          {[...Array(4)].map((_, index) => {
            const item = [
              {
                label: "Total Advances",
                last: (businessRiskData || defaultRiskData).businessRisk.lastTotalAdvances,
                current: (businessRiskData || defaultRiskData).businessRisk.currentTotalAdvances,
                change: (businessRiskData || defaultRiskData).businessRisk.changeTotalAdvances,
              },
              {
                label: "Total Deposits",
                last: (businessRiskData || defaultRiskData).businessRisk.lastTotalDeposits,
                current: (businessRiskData || defaultRiskData).businessRisk.currentTotalDeposits,
                change: (businessRiskData || defaultRiskData).businessRisk.changeTotalDeposits,
              },
              {
                label: "Total NPA",
                last: (businessRiskData || defaultRiskData).businessRisk.lastTotalNpa,
                current: (businessRiskData || defaultRiskData).businessRisk.currentTotalNpa,
                change: (businessRiskData || defaultRiskData).businessRisk.changeTotalNpa,
              },
              {
                label: "Written-Off Advances",
                last: (businessRiskData || defaultRiskData).businessRisk
                  .lastTotalWrittenOffAdvances,
                current: (businessRiskData || defaultRiskData).businessRisk
                  .currentTotalWrittenOffAdvances,
                change: (businessRiskData || defaultRiskData).businessRisk
                  .changeTotalWrittenOffAdvances,
              },
            ][index];

            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ border: "1px solid lightgray" }}>
                  <CardContent>
                    <Typography variant="h6" mb={2}>
                      {item.label}
                    </Typography>
                    <Divider flexItem orientation="horizontal" />
                    <Typography>Last RBIA : {item.last}</Typography>
                    <Typography>Current RBIA : {item.current}</Typography>
                    <Typography>Difference : {item.change} %</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6}>
            <Card sx={{ border: "1px solid lightgray" }}>
              <CardContent>
                <Typography variant="h6" mb={2}>
                  {"Comments"}
                </Typography>
                <Divider flexItem orientation="horizontal" />
                <Typography>
                  {(businessRiskData || defaultRiskData).businessRisk.comments}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default BusinessRiskDashboard;
