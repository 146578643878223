import { useState, useEffect, memo } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// Material Dashboard 2 React context
console.log(navbarMobileMenu);
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import {
  IconArrowBadgeRight,
  IconBell,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled,
  IconLogout,
  IconMenu2,
} from "@tabler/icons-react";
import { Box, Divider, Paper, Popper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";

function DashboardNavbar({ absolute, light, isMini, setSearchValue, show }) {
  const [navbarType, setNavbarType] = useState("sticky");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  // useEffect(() => {
  //   // Setting the navbar type
  //   if (fixedNavbar) {
  //     setNavbarType("sticky")
  //   } else {
  //     setNavbarType("static")
  //   }

  //   // A function that sets the transparent state of the navbar.
  //   function handleTransparentNavbar() {
  //     setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar)
  //   }

  //   /**
  //    The event listener that's calling the handleTransparentNavbar function when
  //    scrolling the window.
  //   */
  //   // window.addEventListener("scroll", handleTransparentNavbar)

  //   // Call the handleTransparentNavbar function to set the state with the initial value.
  //   handleTransparentNavbar()

  //   // Remove event listener on cleanup
  //   // return () => window.removeEventListener("scroll", handleTransparentNavbar)
  // }, [dispatch, fixedNavbar])

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  // const navigate = useNavigate();
  const handleLogOut = () => {
    sessionStorage.clear();
    // navigate("/");
    window.location.href = "/";
  };

  // notifications
  const [notifications, setNotifications] = useState([]);
  const getNotifications = async () => {
    const url = backendUrl + "/onlineAlert/getAll";
    try {
      const res = await axios({
        url: url,
        method: "get",
      });
      if (res.status === 200) {
        setNotifications(res.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("level") === "branch") {
      getNotifications();
    }
  }, []);
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox
            sx={(theme) => {
              navbarRow(theme, { isMini });
            }}
          >
            <MDBox pr={1}>
              {show && (
                <TextField
                  label="Search here"
                  sx={{ display: "inline-block", marginRight: "5px" }}
                  onChange={(e) => (setSearchValue ? setSearchValue(e.target.value) : null)}
                />
              )}
              <Tooltip title="Menu">
                <IconButton
                  disableRipple
                  sx={{
                    background: "#0E5F76",
                    lineHeight: 0,
                    color: "#FFFFFF",
                    display: { xs: "inline-block", xl: "none" },
                    "&:hover": { background: "#D6F14E", color: "#0E5F76" },
                  }}
                  onClick={handleMiniSidenav}
                >
                  {miniSidenav ? (
                    <IconLayoutSidebarLeftExpandFilled style={{ color: "inherit" }} />
                  ) : (
                    <IconLayoutSidebarLeftCollapseFilled style={{ color: "inherit" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Notifications">
                <IconButton
                  size="medium"
                  disableRipple
                  color="inherit"
                  // sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  sx={{
                    position: "relative",
                    background: "#0E5F76",
                    color: "#FFFFFF",
                    "&:hover": { background: "#D6F14E", color: "#0E5F76" },
                    ml: 1,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "3px",
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      background: "red",
                      color: "white",
                    }}
                  >
                    <p style={{ fontSize: 14 }}>{notifications.length}</p>
                  </div>
                  <IconBell style={{ color: "inherit" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Log-Out">
                <IconButton
                  variant="contained"
                  disableRipple
                  sx={{
                    background: "#0E5F76",
                    color: "#FFFFFF",
                    "&:hover": { background: "#D6F14E", color: "#0E5F76" },
                    ml: 1,
                  }}
                  onClick={handleLogOut}
                >
                  <IconLogout style={{ color: "inherit" }} />
                </IconButton>
              </Tooltip>
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>*/}

              <Menu
                anchorEl={openMenu}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    border: "1px solid #ddd",
                    // background: "azure !important",
                  },
                }}
              >
                {/* <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" /> */}
                <Box
                  sx={{
                    position: "relative",
                    maxHeight: "70vh",
                    ":before": {
                      position: "absolute",
                      top: "-20px",
                      height: "10px",
                      width: "10px",
                      padding: 1,
                      background: "red",
                    },
                  }}
                >
                  {notifications.length ? (
                    notifications.map((notification, index) => (
                      <Stack key={index + 1} gap={1}>
                        <p style={{ color: "black", fontSize: 16 }}>
                          <strong>Account Number :</strong> {notification.acctNo}
                        </p>
                        <p
                          style={{
                            color: "black",
                            display: "flex",
                            fontSize: 18,
                            alignItems: "center",
                          }}
                        >
                          <IconArrowBadgeRight color="red" />
                          <p style={{ color: "red" }}>{notification.description}</p>
                        </p>
                        <Stack flexDirection="row" justifyContent="space-between">
                          <p style={{ color: "black" }}>
                            <strong>Date :</strong> {notification.date}
                          </p>
                          <p style={{ color: "black" }}>
                            <strong>Segment :</strong> {notification.segement}
                          </p>
                        </Stack>
                        {notifications.length != index + 1 ? (
                          <Divider sx={{ width: "100%", background: "gray", height: "2px" }} />
                        ) : (
                          <div style={{ marginBottom: 5 }}></div>
                        )}
                      </Stack>
                    ))
                  ) : (
                    <>
                      <Typography variant="h6">All Notifications</Typography>
                      <Divider />
                      <Typography variant="subtitle2" textAlign="center">
                        No Notifications
                      </Typography>
                    </>
                  )}
                </Box>
              </Menu>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default memo(DashboardNavbar);
