import React, { useEffect, useState } from "react";

const CustomLanguageSelector = () => {
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleTranslateScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.onload = () => setIsGoogleScriptLoaded(true);
      script.onerror = () => console.error("Google Translate script failed to load.");
      document.body.appendChild(script);
    };

    if (!window.google || !window.google.translate) {
      loadGoogleTranslateScript();
    } else {
      setIsGoogleScriptLoaded(true);
    }

    window.googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,hi,kn,ml,ta,te,gu,mr,pa,bn",
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          "google_translate_element"
        );
        console.log("Google Translate element initialized");
      }
    };
  }, []);

  const changeLanguage = (event) => {
    const lang = event.target.value;
    const translateElement = document.querySelector("#app");
    if (translateElement && lang) {
      translateElement.value = lang;
      translateElement.dispatchEvent(new Event("change"));
    } else {
      console.error("Google Translate element not found or language not selected.");
    }
  };

  return (
    <div>
      <div
        id="custom_language_selector"
        style={{ display: "flex", justifyContent: "center", margin: "20px" }}
      >
        <select
          onChange={changeLanguage}
          style={{ padding: "10px", fontSize: "16px", width: "100%" }}
          disabled={!isGoogleScriptLoaded}
        >
          <option value="">Select Language</option>
          <option value="en">English</option>
          <option value="hi">Hindi</option>
          <option value="kn">Kannada</option>
          <option value="ml">Malayalam</option>
          <option value="ta">Tamil</option>
          <option value="te">Telugu</option>
          <option value="gu">Gujarati</option>
          <option value="mr">Marathi</option>
          <option value="pa">Punjabi</option>
          <option value="bn">Bengali</option>
        </select>
      </div>
      <div id="google_translate_element" style={{ display: "none" }}></div>
    </div>
  );
};

export default CustomLanguageSelector;
