import React, { Suspense, useState, useEffect, startTransition } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Loader from "components/Loader";
import { useMaterialUIController, setMiniSidenav } from "context";

// Themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Routes Configuration
// import routes from "./routes";
import SignIn from "layouts/authentication/sign-in";

// css
import "./App.css";

// dashboard
import Dashboard from "layouts/dashboard";
import Alerts from "layouts/Alerts_EWS/Alerts";
import Alerts_Authority from "layouts/Alerts_EWS/Alerts_Authority";
import Alerts_FRM from "layouts/Alerts_FRM/Alerts";
import Alerts_Authority_FRM from "layouts/Alerts_FRM/Alerts_Authority";
import DepartmentList from "masters/departments/DepartmentList";
import BranchList from "masters/Branches/BranchList";
import RoleList from "masters/Roles/RoleList";
import UserList from "masters/Users/UserList";
import STD_Summary from "layouts/STD_Accounts/Summary";
import SMA_Summary from "layouts/SMA_Accounts/Summary";
import NPA_Summary from "layouts/NPA_Accounts/Summary";
import Inspection from "layouts/Actions/Inspection";
import Interview from "layouts/Actions/Interview";
import Notice from "layouts/Actions/Notice";
import Compliance from "layouts/Actions/Compliance";
import Events from "layouts/Actions/Events";
import Register_Case from "layouts/Case_Management/Register_Case";
import STD_Dashboard from "layouts/STD_Accounts/AccDashboard";
import SMA_Dashboard from "layouts/SMA_Accounts/AccDashboard";
import NPA_Dashboard from "layouts/NPA_Accounts/AccDashboard";
import Orm from "layouts/Audit/Orm";
import Crm from "layouts/Audit/Crm";
import CrmValueStatements from "layouts/Audit/CrmValueStatements";
import BusinessRisk from "layouts/BusinessRisk/BusinessRisk";
import BusinessRiskDashboard from "layouts/BusinessRisk/Dashboard/BusinessRiskDashboard";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      startTransition(() => {
        setMiniSidenav(dispatch, false);
      });
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      startTransition(() => {
        setMiniSidenav(dispatch, true);
      });
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const loggedin = sessionStorage.getItem("token");

  const PrivateRoute = ({ children }) => {
    const token = sessionStorage.getItem("token");
    return token ? children : <Navigate to="/" />;
  };

  const level_authority = sessionStorage.getItem("level_authority");

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {loggedin && (
        <Sidenav
          color={sidenavColor}
          brandName="S.S.B Digital"
          // routes={routes}
          // onMouseEnter={handleOnMouseEnter}
          // onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<SignIn />} />

        {/* Private Routes */}
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<Loader />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/departments"
          element={
            <Suspense fallback={<Loader />}>
              <DepartmentList />
            </Suspense>
          }
        />
        <Route
          path="/branches"
          element={
            <Suspense fallback={<Loader />}>
              <BranchList />
            </Suspense>
          }
        />
        <Route
          path="/roles"
          element={
            <Suspense fallback={<Loader />}>
              <RoleList />
            </Suspense>
          }
        />
        <Route
          path="/users"
          element={
            <Suspense fallback={<Loader />}>
              <UserList />
            </Suspense>
          }
        />
        <Route
          path="/Operation-Risk-Management"
          element={
            <Suspense fallback={<Loader />}>
              <Orm />
            </Suspense>
          }
        />
        <Route
          path="/Credit-Risk-Management"
          element={
            <Suspense fallback={<Loader />}>
              <Crm />
            </Suspense>
          }
        />
        <Route
          path="/Crm/:id"
          element={
            <Suspense fallback={<Loader />}>
              <CrmValueStatements />
            </Suspense>
          }
        />

        <Route
          path="/Business-Risk-Form"
          element={
            <Suspense fallback={<Loader />}>
              <BusinessRisk />
            </Suspense>
          }
        />
        <Route
          path="/business-dashboard"
          element={
            <Suspense fallback={<Loader />}>
              <BusinessRiskDashboard />
            </Suspense>
          }
        />

        {/* Nested Routes for Account Categories */}
        {["standard", "sma", "npa"].map((type) => (
          <Route key={type} path={`/${type}/summary`} element={<Outlet />}>
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  {type === "standard" ? (
                    <STD_Summary />
                  ) : type === "sma" ? (
                    <SMA_Summary />
                  ) : (
                    <NPA_Summary />
                  )}
                </Suspense>
              }
            />
            <Route
              path="dashboard/:id"
              element={
                <Suspense fallback={<Loader />}>
                  {type === "standard" ? (
                    <STD_Dashboard />
                  ) : type === "sma" ? (
                    <SMA_Dashboard />
                  ) : (
                    <NPA_Dashboard />
                  )}
                </Suspense>
              }
            />
            <Route
              path="cases/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Register_Case />
                </Suspense>
              }
            />
            <Route
              path="inspection/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Inspection />
                </Suspense>
              }
            />
            <Route
              path="interview/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Interview />
                </Suspense>
              }
            />
            <Route
              path="notice/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Notice />
                </Suspense>
              }
            />
            <Route
              path="compliance/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Compliance />
                </Suspense>
              }
            />
            {type === "npa" && (
              <Route
                path="events/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Events />
                  </Suspense>
                }
              />
            )}
          </Route>
        ))}
        {/* Alerts */}
        {level_authority == "branch" ? (
          <>
            <Route
              path="/EWS_Alerts"
              element={
                <Suspense fallback={<Loader />}>
                  <Alerts />
                </Suspense>
              }
            />
            <Route
              path="/FRM_Alerts"
              element={
                <Suspense fallback={<Loader />}>
                  <Alerts_FRM />
                </Suspense>
              }
            />
          </>
        ) : (
          <>
            <Route
              path="/EWS_Alerts"
              element={
                <Suspense fallback={<Loader />}>
                  <Alerts_Authority />
                </Suspense>
              }
            />
            <Route
              path="/FRM_Alerts"
              element={
                <Suspense fallback={<Loader />}>
                  <Alerts_Authority_FRM />
                </Suspense>
              }
            />
          </>
        )}

        {/* Catch-All */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
