// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import PieChart from "./components/PieChart";
import StatusChart from "./components/StatusChart";
import TurningNPA from "./components/TurningNPA/TurningNPA";
import NoActionTaken from "./components/NoAction/NoActionTaken";
import ActionsDueAccounts from "./components/ActionDue/ActionsDueAccounts";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "components/Loader";
import BarChart from "./components/Bar Chart";
import { useNavigate } from "react-router-dom";
import OfflineAlertsPieChart from "./components/OfflineAlertPieChart";
import AlertsCount from "./components/AlertsCount";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const url = process.env.REACT_APP_BACKEND_URL + "/dashboard/data";
  const getDashboardData = async () => {
    try {
      const res = await axios.get(`${url}`);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDashboardData();
  }, []);

  const navigate = useNavigate();
  const handleView = (number, category) => {
    if (!category && category != 0) {
      return;
    }
    let visitUrl = null;
    if (category == 0) {
      visitUrl = `/standard/summary/dashboard/${number}`;
    } else if (category == 1 || category == 2 || category == 3) {
      visitUrl = `/sma/summary/dashboard/${number}`;
    } else {
      visitUrl = `/npa/summary/dashboard/${number}`;
    }
    navigate(visitUrl);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox py={3}>
          {/* <Grid container spacing={3}> */}
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Bookings"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
          {/* </Grid> */}
          {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
          <MDBox>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <AlertsCount />
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <PieChart data={data?.pieChart} handleView={handleView} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StatusChart
                  actionDueAccounts={data?.actionDueTable?.length}
                  noActionTakenAccounts={data?.noActionTable?.length}
                  npaTurningAccounts={data?.npaTurningTable?.length}
                />
              </Grid>
              <Grid item xs={12}>
                <OfflineAlertsPieChart
                  data={data?.offlineAlertsCount || []}
                  handleView={handleView}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <BarChart />
              </Grid> */}
              <Grid item xs={12}>
                <NoActionTaken
                  data={data?.noActionTable?.length ? data?.noActionTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <ActionsDueAccounts
                  data={data?.actionDueTable?.length ? data?.actionDueTable : []}
                  handleView={handleView}
                />
              </Grid>
              <Grid item xs={12}>
                <TurningNPA
                  data={data?.npaTurningTable?.length ? data.npaTurningTable : []}
                  handleView={handleView}
                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
