// @mui material components
import { forwardRef, useEffect, useState } from "react";
import React from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Box,
} from "@mui/material";
// components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
// date converter
import moment from "moment";
// toast message
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/Loader";
import { styled } from "@mui/material/styles";

// new interview modal transition
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Interview() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  // new interview modal
  const [newInterviewModal, setNewInterviewModal] = useState(false);
  const handleNewInterview = () => {
    setNewInterviewModal(true);
  };
  const handleClose = () => {
    setNewInterviewModal(false);
  };

  //   new interview details
  const initialFormData = {
    personInterviewed: "",
    interviewerName: "",
    witnessName: "",
    interviewMinutes: "",
    summary: "",
    interviewImagesName: [],
    geoLocation: "",
  };

  const [interviewErrors, setInterviewErrors] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const validate = () => {
    const newErrors = {};
    let isValid = true;
    if (!formData.personInterviewed.trim()) {
      newErrors.personInterviewed = "Name Is Required";
      isValid = false;
    }
    if (!formData.interviewerName.trim()) {
      newErrors.interviewerName = "Provide Interviewer Name";
      isValid = false;
    }
    if (!formData.witnessName.trim()) {
      newErrors.witnessName = "Provide Witness Name";
      isValid = false;
    }
    if (!formData.summary.trim()) {
      newErrors.summary = "Provide Summary";
      isValid = false;
    }
    if (!formData.interviewMinutes.trim()) {
      newErrors.interviewMinutes = "Provide Interview Minutes";
      isValid = false;
    }
    setInterviewErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleFileUpload = async (field, e) => {
    let url = backendUrl + "/Interviews/uploadImages";
    const receivedFiles = e.target.files;
    try {
      const body = new FormData();
      for (let i = 0; i < receivedFiles.length; i++) {
        body.append("files", receivedFiles[i]);
      }
      const res = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File uploaded");
      }
    } catch (error) {
      toast.error("Error upload file");
    }
    const fileNames = Array.from(receivedFiles).map((file) => file.name);
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ...fileNames],
    }));
  };
  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

          // Update the geoLocation field in the form data
          setFormData((prevData) => ({
            ...prevData,
            geoLocation: `Latitude: ${latitude}, Longitude: ${longitude}`,
          }));

          // You can do something with the latitude and longitude values here
        },
        (error) => {
          console.error(`Geolocation error: ${error.message}`);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  };

  const handleSubmit = async () => {
    const url = backendUrl + "/Interviews/create";
    const valid = validate();
    if (valid) {
      try {
        const res = await axios.post(url, { ...formData, accountNumber: id });
        if (res.status === 200) {
          toast.success("Form Submitted");
          setFormData(initialFormData);
          getInterviewHistory();
        }
      } catch (error) {
        toast.error("Unable to save interview");
      }
      handleClose();
    }
  };

  const getInterviewHistory = async () => {
    const url = backendUrl + `/Interviews/history/${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInterviewHistory();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Interview History
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 220px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Person interviewed
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Interviewer Name
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Place
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Summary
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    sx={{ fontWeight: "700" }}
                    align="left"
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.length ? (
                  data?.map((row) => {
                    return (
                      <StyledTableRow key={row.interview_id}>
                        <TableCell className="NewTableCells" align="left">
                          {row?.personInterviewed ? row?.personInterviewed : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {row?.interviewerName ? row?.interviewerName : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {row.createDateTime
                            ? moment(row?.createDateTime).format("DD-MM-YYYY hh:mm")
                            : "---"}
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {row?.place ? row?.place : "---"}
                        </TableCell>
                        <TableCell
                          className="NewTableCells"
                          align="left"
                          sx={{ maxWidth: "200px", overflow: "hidden" }}
                        >
                          <div style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                            {row.summary || "---"}
                          </div>
                        </TableCell>
                        <TableCell className="NewTableCells" align="left">
                          {row.report ? (
                            <a href={row.report} download>
                              <Button>Download</Button>
                            </a>
                          ) : (
                            "---"
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              className="ActionButtonsColor"
              variant="contained"
              onClick={handleNewInterview}
              sx={{ color: "white !important", background: "#49a3ffff" }}
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}
      <Dialog
        open={Boolean(newInterviewModal)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Record new interview"}</DialogTitle>
        <DialogContent>
          <Stack p={1} gap={1}>
            <TextField
              label="Person Interviewed"
              fullWidth
              value={formData.personInterviewed}
              onChange={(e) => handleInputChange("personInterviewed", e.target.value)}
              required
              error={!!interviewErrors.personInterviewed}
              helperText={interviewErrors.personInterviewed}
            />
            <TextField
              label="Interviewer Name"
              fullWidth
              value={formData.interviewerName}
              onChange={(e) => handleInputChange("interviewerName", e.target.value)}
              required
              error={!!interviewErrors.interviewerName}
              helperText={interviewErrors.interviewerName}
            />
            <TextField
              label="Witness Name"
              fullWidth
              value={formData.witnessName}
              onChange={(e) => handleInputChange("witnessName", e.target.value)}
              required
              error={!!interviewErrors.witnessName}
              helperText={interviewErrors.witnessName}
            />
            <TextField
              label="Interview Minutes"
              fullWidth
              type="number"
              value={formData.interviewMinutes}
              onChange={(e) => handleInputChange("interviewMinutes", e.target.value)}
              required
              error={!!interviewErrors.interviewMinutes}
              helperText={interviewErrors.interviewMinutes}
            />
            <TextField
              label="Interview Summary"
              fullWidth
              multiline
              rows={4}
              value={formData.summary}
              onChange={(e) => handleInputChange("summary", e.target.value)}
              required
              error={!!interviewErrors.summary}
              helperText={interviewErrors.summary}
            />
            <Stack flexDirection="row" alignItems="center" gap="5px">
              <TextField label="Geo Location" fullWidth value={formData.geoLocation} disabled />
              <Button
                onClick={getGeoLocation}
                variant="contained"
                sx={{ height: "40px", color: "white !important" }}
              >
                Capture
              </Button>
            </Stack>
            <Box>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="interview-image-upload"
                type="file"
                onChange={(e) => handleFileUpload("interviewImagesName", e)}
              />
              <label htmlFor="interview-image-upload">
                <Button variant="contained" component="span" sx={{ color: "white !important" }}>
                  Upload Interview Images
                </Button>
              </label>
            </Box>

            {formData.interviewImagesName.length ? (
              <TableContainer sx={{ boxShadow: 0, border: "1px solid", overflowX: "visible" }}>
                <Table>
                  <TableRow>
                    <TableCell>Sr_No</TableCell>
                    <TableCell>File</TableCell>
                  </TableRow>
                  <TableBody>
                    {formData.interviewImagesName.map((file, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{file}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Interview;
