import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Paper,
  Box,
  Stack,
  Button,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "components/Loader";
import moment from "moment";
import { styled } from "@mui/material/styles";
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconClipboardPlus,
  IconFileSearch,
  IconReport,
} from "@tabler/icons-react";

const AccDashboard = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [followUpType, setFollowUpType] = useState([]);
  const url = backendUrl + "/account/StandardAccount/" + id;
  const getData = async () => {
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const getFollowUpDetails = async () => {
    const followUrl = backendUrl + `/ActtionAccount/follow-ups?accountNumber=${id}`;

    try {
      const res = await axios.get(followUrl);
      if (res.status === 200) {
        setFollowUpType(res.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getData();
    getFollowUpDetails();
  }, []);

  // generate report
  const [reportLoading, setReportLoading] = useState(false);
  const handleClick = async (id) => {
    setReportLoading(true); // Show loading indicator

    const url = backendUrl + `/AlertReport/generate/${id}`;

    try {
      const response = await fetch(url);

      // Handle HTTP errors
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a Blob object from the response
      const blob = await response.blob();

      // Create a URL for the Blob object
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element
      const anchor = document.createElement("a");
      anchor.style.display = "none";
      anchor.href = blobUrl;
      anchor.setAttribute("download", "");

      // Append anchor to the body and click to trigger download
      document.body.appendChild(anchor);
      anchor.click();

      // Clean up
      document.body.removeChild(anchor);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error.message);
      // Handle error as needed
    } finally {
      setReportLoading(false); // Hide loading indicator
    }
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loading ? (
        <Loader />
      ) : (
        <>
          <MDBox
            sx={{
              my: 3,
              border: "1px solid lightgray",
              // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              backgroundColor: "white !important",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ textAlign: "center", mb: 3, borderBottom: "1px solid", height: "40px" }}
            >
              A/C Summary
            </Typography>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Account No:</strong>
                  <br />
                  {data?.acctNo ? data?.acctNo : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>CIF No:</strong> <br />
                  {data?.cifNumber ? data?.cifNumber : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Name:</strong> <br />
                  {data?.custName ? data?.custName : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Mobile No:</strong> <br />
                  {data?.telNo ? data?.telNo : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Sanction Date:</strong> <br />
                  {data?.sanctDt ? data?.sanctDt : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Tenure:</strong> <br />
                  {data?.emisDue || data?.emisDue === 0 ? data?.emisDue + " Months" : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Outstand Rs:</strong> <br />
                  {data?.outstand ? data?.outstand : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Segment:</strong> <br />
                  {data?.segement ? data?.segement : "---"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography variant="body1">
                  <strong>Product Code:</strong> <br />
                  {data?.productCode ? data?.productCode : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography variant="h5" sx={{ textAlign: "center", mb: "10px" }}>
                <u>Actions</u>
              </Typography>
              <Stack flexDirection="row" justifyContent="center" gap={2} flexWrap="wrap">
                <Button
                  className="ActionButtonsColor"
                  disabled={reportLoading}
                  sx={{ color: "white !important", minWidth: "200px" }}
                  variant="contained"
                  to={`/standard/summary/inspection/${data?.acctNo}`}
                  component={Link}
                >
                  <IconFileSearch style={{ marginRight: 2 }} />
                  Inspection
                </Button>
                <Button
                  className="ActionButtonsColor"
                  disabled={reportLoading}
                  sx={{ color: "white !important", minWidth: "200px" }}
                  variant="contained"
                  to={`/standard/summary/compliance/${data?.acctNo}`}
                  component={Link}
                >
                  <IconAlertTriangle style={{ marginRight: 2 }} />
                  Compliances
                </Button>
                <Button
                  className="ActionButtonsColor"
                  disabled={reportLoading}
                  sx={{ color: "white !important", minWidth: "200px" }}
                  variant="contained"
                  to={`/standard/summary/cases/${data?.acctNo}`}
                  component={Link}
                >
                  <IconClipboardPlus style={{ marginRight: 2 }} />
                  Case Register
                </Button>
                <Button
                  className="ActionButtonsColor"
                  disabled={reportLoading}
                  sx={{ color: "white !important", minWidth: "200px" }}
                  variant="contained"
                  // to={`http://192.168.1.36:8080/AlertReport/generate/${data?.acctNo}`}
                  // component={Link}
                  onClick={() => handleClick(data?.acctNo)}
                >
                  {reportLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <IconReport style={{ marginRight: 2 }} />
                      Generate Reoprt
                    </>
                  )}
                </Button>
              </Stack>
            </Box>
          </MDBox>
          {/* <Grid container spacing={3}>
        <Grid item xs={8}> */}
          <Typography variant="h5" sx={{ ml: "3px" }}>
            Follow Up Details :
          </Typography>
          <TableContainer
            sx={{
              maxHeight: "550px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                  }}
                >
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    sx={{ fontWeight: "700", width: "25%" }}
                  >
                    A/C No
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    sx={{ fontWeight: "700", width: "25%" }}
                  >
                    Follow up type
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    sx={{ fontWeight: "700", width: "25%" }}
                  >
                    Summary
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    sx={{ fontWeight: "700", width: "25%" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="NewTableHeaderFontColor"
                    align="left"
                    sx={{ fontWeight: "700", width: "25%" }}
                  >
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {followUpType?.length ? (
                  followUpType.map((item, index) => (
                    <StyledTableRow key={index}>
                      <TableCell className="NewTableCells" align="left">
                        {item.accountNumber || "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {item.followUpType || "---"}
                      </TableCell>
                      <TableCell
                        className="NewTableCells"
                        align="left"
                        sx={{ maxWidth: "200px", overflow: "hidden" }}
                      >
                        <div style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                          {item.summary || "---"}
                        </div>
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {item.date ? moment(item.date).format("DD-MM-YYYY hh:mm") : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {item.report ? (
                          <a href={item.report} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ borderRight: "0px !important" }} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </Grid>
      </Grid> */}
        </>
      )}
    </DashboardLayout>
  );
};

export default AccDashboard;
