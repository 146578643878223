import React, { useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

const MsmeForm = ({ msmeFormData, setMsmeFormData, msmeErrors }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setMsmeFormData({ ...msmeFormData, [name]: value });
  };

  const handleLimitChange = (index, field, value) => {
    const updatedLimits = [...msmeFormData.limits];
    updatedLimits[index][field] = value;
    setMsmeFormData({ ...msmeFormData, limits: updatedLimits });
  };

  const handleAddLimit = () => {
    setMsmeFormData({
      ...msmeFormData,
      limits: [...msmeFormData.limits, { facility: "", limit: "" }],
    });
  };
  const handleRemoveLimit = (index) => {
    const updatedLimits = [...msmeFormData.limits];
    updatedLimits.splice(index, 1);
    setMsmeFormData({ ...msmeFormData, limits: updatedLimits });
  };

  return (
    <>
      <TextField
        sx={{ mt: 1 }}
        label="Date of Visit"
        type="datetime-local"
        name="dateOfVisit"
        value={msmeFormData.dateOfVisit}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.dateOfVisit}
        helperText={msmeErrors.dateOfVisit}
        InputLabelProps={{ shrink: true }} // This ensures the label doesn't overlap
      />

      <TextField
        label="Purpose of Visit"
        name="purposeOfVisit"
        value={msmeFormData.purposeOfVisit}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.purposeOfVisit}
        helperText={msmeErrors.purposeOfVisit}
      />
      <TextField
        label="Name of Person Interviewed"
        name="nameOfPersonInterviewed"
        value={msmeFormData.nameOfPersonInterviewed}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.nameOfPersonInterviewed}
        helperText={msmeErrors.nameOfPersonInterviewed}
      />
      <TextField
        label="Comment on Activity of Firm"
        name="commentOnActivityOfFirm"
        value={msmeFormData.commentOnActivityOfFirm}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={4}
      />
      <TextField
        label="Comments about General Working Company"
        name="commentsAboutGeneralWorkingCompany"
        value={msmeFormData.commentsAboutGeneralWorkingCompany}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={4}
      />
      <TextField
        label="Data Collection Done (If Any)"
        name="dataCollectionDone"
        value={msmeFormData.dataCollectionDone}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Inspection Conducted By"
        name="inspectionConductedBy"
        value={msmeFormData.inspectionConductedBy}
        onChange={handleInputChange}
        fullWidth
        required
        error={!!msmeErrors.inspectionConductedBy}
        helperText={msmeErrors.inspectionConductedBy}
      />
      <div>
        {/* <Typography variant="h5">Limits</Typography> */}
        <Button
          variant="contained"
          sx={{ color: "white !important" }}
          // color="primary"
          onClick={handleAddLimit}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Limit
        </Button>
        {msmeFormData.limits.length ? (
          <TableContainer sx={{ mt: 1 }}>
            <Table>
              <TableHead sx={{ width: "100%", display: "contents" }}>
                <TableRow>
                  <TableCell>Facility</TableCell>
                  <TableCell>Limit (Rs)</TableCell>
                  <TableCell>Action</TableCell> {/* New column for remove button */}
                </TableRow>
              </TableHead>
              <TableBody>
                {msmeFormData.limits.map((limit, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        value={limit.facility}
                        onChange={(e) => handleLimitChange(index, "facility", e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={limit.limit}
                        onChange={(e) => handleLimitChange(index, "limit", e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleRemoveLimit(index)} startIcon={<DeleteIcon />} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
    </>
  );
};

export default MsmeForm;
