import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import { backendUrl } from "utils/env";
import { token } from "utils/userToken";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { IconDownload } from "@tabler/icons-react";

export default function Crm() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [reportLoading, setReportLoading] = useState({}); // track loading state for each row

  // Fetch accounts data
  const getAccount = async () => {
    const url = backendUrl + "/rbia/CRM/Accounts";
    try {
      const res = await axios({
        method: "get",
        url: url,
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccounts(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  // Handle sorting logic
  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortDirection(direction);
  };

  const sortedData = accounts.sort((a, b) => {
    if (!sortColumn) return 0;
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  // Handle search filtering
  const filteredData = sortedData.filter(
    (row) =>
      row.acctNo?.toString().includes(searchValue) ||
      row.description?.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const StyledTableRow = styled(TableRow)(({ theme, comment }) => ({
    backgroundColor: comment ? "#ffcccc !important" : "",
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  const renderSortArrow = (column) => {
    if (sortColumn !== column) return null;
    return sortDirection === "asc" ? " \u2191" : " \u2193";
  };

  // Redirect for particular account
  const navigate = useNavigate();
  const handleCRM = (accountNo) => {
    navigate(`/crm/${accountNo}`);
  };

  // Handle Report button click (API call)
  const handleReportClick = (accountNo) => {
    setReportLoading((prevState) => ({
      ...prevState,
      [accountNo]: true, // Set loading state for the specific account
    }));

    try {
      const url = `${backendUrl}/accountWiseCRM/downloadPdf/${accountNo}`;
      window.open(url, "_blank"); // Open the URL in a new tab
    } catch (error) {
      console.log(error);
    } finally {
      setReportLoading((prevState) => ({
        ...prevState,
        [accountNo]: false, // Reset loading state after the operation
      }));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar setSearchValue={setSearchValue} loading={loading} show={true} />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 190px)",
              boxShadow: 0,
              border: "1px solid lightgray",
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    backgroundColor: "#49a3f1",
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No {renderSortArrow("acctNo")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Name {renderSortArrow("custName")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact {renderSortArrow("telNo")}
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentData.length ? (
                  currentData.map((row, index) => (
                    <StyledTableRow key={index + 1} comment={row.rejectComments}>
                      <TableCell className="NewTableCells" align="left">
                        {row.acctNo}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.custName}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        {row.telNo}
                      </TableCell>
                      <TableCell className="NewTableCells" align="left">
                        <Button disabled={row.crmDone} onClick={() => handleCRM(row.acctNo)}>
                          CRM
                        </Button>
                        <Button
                          disabled={!row.crmDone || reportLoading[row.acctNo]}
                          onClick={() => handleReportClick(row.acctNo)}
                        >
                          {reportLoading[row.acctNo] ? (
                            <CircularProgress size={24} style={{ marginRight: "10px" }} />
                          ) : (
                            <IconDownload style={{ marginRight: "10px" }} />
                          )}
                          Report
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>No Data</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </MDBox>
      )}
    </DashboardLayout>
  );
}
